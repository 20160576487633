import React, { useState, useEffect } from 'react'
import LinkFormatter from 'components/LinkFormatter'
import { chunk } from 'lodash'
import {
  MosaicWrapperContainer,
  MainGalleryContainer,
  ImageTall,
  ImageShort,
  ImageShortContainer,
  StyledTitle,
  HoverContainerTall,
  HoverContainerShort,
  StyledCategory,
} from './style'

const MosaicWrapper = ({ projects }) => {
  const [imageMosaicObject, setImageMosaicObject] = useState([])

  useEffect(() => {
    if (projects?.length > 0) {
      const chunks = chunk(projects, [3])
      setImageMosaicObject(chunks)
    }
  }, [projects])

  return (
    <MosaicWrapperContainer className="Mosaic__Wrapper">
      {imageMosaicObject?.length > 0 ? (
        imageMosaicObject.map((row, index) => {
          return (
            <MainGalleryContainer key={index}>
              {row?.[0]?.data?.mosaic_thumbnail?.localFile?.childImageSharp
                ?.fluid ? (
                <ImageTall
                  fluid={
                    row?.[0]?.data?.mosaic_thumbnail?.localFile?.childImageSharp
                      ?.fluid
                  }
                  className="Mosaic__ImageTall"
                  alt={row?.[0]?.data?.mosaic_thumbnail?.alt}
                >
                  {row?.[0]?.uid &&
                  row?.[0]?.data?.project_title?.text &&
                  row?.[0]?.data?.project_category ? (
                    <LinkFormatter
                      pageType={row?.[0]?.data?.page_type}
                      pageHandle={row?.[0]?.uid}
                    >
                      <HoverContainerTall className="Mosaic__HoverContainerTall">
                        <StyledTitle
                          whiteTitle
                          type="h4"
                          as="h4"
                          className="Mosaic__Title"
                        >
                          {row?.[0]?.data?.project_title?.text}
                        </StyledTitle>
                        <StyledCategory
                          type="secondary-small"
                          whiteText
                          uppercase
                          as="p"
                          className="Mosaic__StyledCategory"
                        >
                          {row?.[0]?.data?.project_category}
                        </StyledCategory>
                      </HoverContainerTall>
                    </LinkFormatter>
                  ) : (
                    <></>
                  )}
                </ImageTall>
              ) : (
                <></>
              )}

              <ImageShortContainer>
                {row?.[1]?.data?.mosaic_thumbnail?.localFile?.childImageSharp
                  ?.fluid ? (
                  <ImageShort
                    fluid={
                      row?.[1]?.data?.mosaic_thumbnail?.localFile
                        ?.childImageSharp?.fluid
                    }
                    className="ImageShort__firstChild Mosaic__ImageShort"
                    alt={row?.[1]?.data?.mosaic_thumbnail?.alt}
                  >
                    {row?.[1]?.uid &&
                    row?.[1]?.data?.project_title?.text &&
                    row?.[1]?.data?.project_category ? (
                      <LinkFormatter
                        pageType={row?.[1]?.data?.page_type}
                        pageHandle={row?.[1]?.uid}
                      >
                        <HoverContainerShort className="Mosaic__HoverContainerShort">
                          <StyledTitle
                            whiteTitle
                            type="h4"
                            as="h4"
                            className="Mosaic__Title"
                          >
                            {row?.[1]?.data?.project_title?.text}
                          </StyledTitle>
                          <StyledCategory
                            type="secondary-small"
                            as="p"
                            whiteText
                            uppercase
                            className="Mosaic__StyledCategory"
                          >
                            {row?.[1]?.data?.project_category}
                          </StyledCategory>
                        </HoverContainerShort>
                      </LinkFormatter>
                    ) : (
                      <></>
                    )}
                  </ImageShort>
                ) : (
                  <></>
                )}

                {row?.[2]?.data?.mosaic_thumbnail?.localFile?.childImageSharp
                  ?.fluid ? (
                  <ImageShort
                    fluid={
                      row?.[2]?.data?.mosaic_thumbnail?.localFile
                        ?.childImageSharp?.fluid
                    }
                    className="Mosaic__ImageShort"
                    alt={row?.[2]?.data?.mosaic_thumbnail?.alt}
                  >
                    {row?.[2]?.uid &&
                    row?.[2]?.data?.project_title?.text &&
                    row?.[2]?.data?.project_category ? (
                      <LinkFormatter
                        pageType={row?.[2]?.data?.page_type}
                        pageHandle={row?.[2]?.uid}
                      >
                        <HoverContainerShort className="Mosaic__HoverContainerShort">
                          <StyledTitle
                            whiteTitle
                            type="h4"
                            as="h4"
                            className="Mosaic__Title"
                          >
                            {row?.[2]?.data?.project_title?.text}
                          </StyledTitle>
                          <StyledCategory
                            type="secondary-small"
                            whiteText
                            uppercase
                            as="p"
                            className="Mosaic__StyledCategory"
                          >
                            {row?.[2]?.data?.project_category}
                          </StyledCategory>
                        </HoverContainerShort>
                      </LinkFormatter>
                    ) : (
                      <></>
                    )}
                  </ImageShort>
                ) : (
                  <></>
                )}
              </ImageShortContainer>
            </MainGalleryContainer>
          )
        })
      ) : (
        <></>
      )}
    </MosaicWrapperContainer>
  )
}

export default MosaicWrapper
