import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Hero from 'components/Hero'
import { ProjectsLayoutContainer, SimpleWrapperContainer } from './style'
import Footer from 'components/Footer'
import CategoryFilter from 'components/CategoryFilter'
import { LargeDesktopUp, LargeDesktopDown } from 'components/Utilities/Media'
import MosaicWrapper from 'components/MosaicWrapper'
import MosaicWrapperMobile from 'components/MosaicWrapper/MosaicWrapperMobile'

const ProjectsLayout = ({
  query,
  allCategoriesLabel,
  mosaicQuery,
  globalQuery,
}) => {
  const [category, setCategory] = useState(allCategoriesLabel?.toLowerCase())
  const [projects, setProjects] = useState(null)
  const [categories, setCategories] = useState(null)

  useEffect(() => {
    if (mosaicQuery?.length > 0) {
      setProjects(mosaicQuery)

      setCategories([
        ...new Set(mosaicQuery.map(el => el.data.project_category)),
      ])
    }
  }, [mosaicQuery])

  useEffect(() => {
    if (
      category?.toLowerCase() !== allCategoriesLabel?.toLowerCase() &&
      category !== null
    ) {
      setProjects(
        mosaicQuery?.filter(
          q =>
            q?.data?.project_category?.toLowerCase() === category.toLowerCase()
        )
      )
    } else {
      setProjects(mosaicQuery)
    }
  }, [category])

  const filterProps = {
    allLabel: allCategoriesLabel,
    category,
    setCategory,
    categories,
  }

  const heroProps = { ...query?.hero_component?.document?.data, ...globalQuery }

  return (
    <ProjectsLayoutContainer>
      <Hero {...heroProps} />
      {mosaicQuery ? (
        <SimpleWrapperContainer>
          <CategoryFilter {...filterProps} />
          {projects ? (
            <>
              <LargeDesktopUp>
                <MosaicWrapper projects={projects} />
              </LargeDesktopUp>
              <LargeDesktopDown>
                <MosaicWrapperMobile projects={projects} />
              </LargeDesktopDown>
            </>
          ) : (
            <></>
          )}
        </SimpleWrapperContainer>
      ) : (
        <></>
      )}
      <Footer />
    </ProjectsLayoutContainer>
  )
}

ProjectsLayout.propTypes = {
  mosaicQuery: PropTypes.array.isRequired,
  genericQuery: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  globalQuery: PropTypes.object.isRequired,
}

export default ProjectsLayout
