import React, { useContext, useEffect } from 'react'
import LocaleContext from 'context/LocaleProvider'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import ProjectsLayout from 'components/PagesLayout/ProjectsLayout'
import { withPreview } from 'gatsby-source-prismic'

const Projects = ({ data }) => {
  const lang = useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const locale = i18n.locale
  const genericQuery = data?.allPrismicProjectsGeneral?.nodes?.[0]?.data
  const heroQuery = data?.allPrismicProjectsLanding?.nodes?.[0]?.data
  const mosaicQuery = data?.allPrismicSingleProjectPage?.nodes
  const globalQuery = data?.allPrismicGlobalContent?.nodes?.[0]?.data
  const allCategoriesLabel = mosaicQuery?.[0]?.data?.all_categories_label?.text

  useEffect(() => {
    lang?.setPageDetails({
      uid:
        data.allPrismicProjectsLanding.nodes?.[0].alternate_languages?.[0]?.uid,
      type: 'page',
    })
  }, [])

  return (
    <>
      {heroQuery && genericQuery && mosaicQuery && globalQuery ? (
        <ProjectsLayout
          query={heroQuery}
          genericQuery={genericQuery}
          mosaicQuery={mosaicQuery}
          globalQuery={globalQuery}
          locale={locale}
          allCategoriesLabel={allCategoriesLabel}
        />
      ) : (
        <></>
      )}
    </>
  )
}

Projects.propTypes = {
  data: PropTypes.object.isRequired,
}

export default withPreview(Projects)

export const projectsLandingQuery = graphql`
  query projectsLandingQuery($locale: String!) {
    allPrismicGlobalContent(filter: { lang: { eq: $locale } }) {
      nodes {
        data {
          scroll_down_label {
            text
          }
          read_more {
            text
          }
        }
      }
    }
    allPrismicProjectsLanding(filter: { lang: { eq: $locale } }) {
      nodes {
        lang
        alternate_languages {
          uid
        }
        data {
          seo_component {
            document {
              __typename
              ... on PrismicSeo {
                id
                data {
                  breadcrumb_title {
                    text
                  }
                  open_graph_title {
                    text
                  }
                  open_graph_description {
                    text
                  }
                  open_graph_image {
                    alt
                    localFile {
                      url
                    }
                  }
                  seo_title {
                    text
                  }
                  seo_description {
                    text
                  }
                }
              }
            }
          }
          hero_component {
            document {
              ... on PrismicHeroComponent {
                id
                data {
                  full_screen_height
                  body {
                    text
                  }
                  image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                  title {
                    text
                  }
                  title_style
                  video {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicProjectsGeneral(filter: { lang: { eq: $locale } }) {
      nodes {
        lang
        data {
          cta {
            page_type
            cta_styling
            cta_link {
              uid
            }
            cta_label {
              text
            }
          }
          tab_1_title {
            text
          }
          tab_2_title {
            text
          }
          tab_3_title {
            text
          }
          tab_4_title {
            text
          }
        }
      }
    }
    allPrismicSingleProjectPage(filter: { lang: { eq: $locale } }) {
      nodes {
        lang
        uid
        data {
          page_type
          mosaic_thumbnail {
            alt
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          project_category
          all_categories_label {
            text
          }
          project_excerpt {
            text
          }
          project_tags {
            text
          }
          project_title {
            text
          }
        }
      }
    }
  }
`
