import React from 'react'
import PropTypes from 'prop-types'
import {
  MosaicWrapperMobileContainer,
  MobileContainerTall,
  MobileContainerShort,
  StyledLink,
} from './style'
import { StyledTitle, StyledCategory } from './../style'

const MosaicWrapperMobile = ({ projects }) => {
  return (
    <MosaicWrapperMobileContainer>
      {projects?.length > 0 &&
        projects?.map((item, index) => {
          if (index % 2 === 0) {
            return (
              <MobileContainerTall
                fluid={
                  item?.data?.mosaic_thumbnail?.localFile?.childImageSharp
                    ?.fluid
                }
                key={index}
                alt={item?.data?.mosaic_thumbnail?.alt}
              >
                {item.uid &&
                item?.data?.project_title?.text &&
                item?.data?.project_category ? (
                  <StyledLink
                    pageType={item?.data?.page_type}
                    pageHandle={item.uid}
                  >
                    <StyledTitle
                      whiteTitle
                      type="h4"
                      className="Mosaic__MobileTitle"
                    >
                      {item?.data?.project_title?.text}
                    </StyledTitle>
                    <StyledCategory
                      type="secondary-small"
                      whiteText
                      uppercase
                      className="Mosaic__MobileCategory"
                    >
                      {item?.data?.project_category}
                    </StyledCategory>
                  </StyledLink>
                ) : (
                  <></>
                )}
              </MobileContainerTall>
            )
          } else {
            return (
              <MobileContainerShort
                fluid={
                  item?.data?.mosaic_thumbnail?.localFile?.childImageSharp
                    ?.fluid
                }
                key={index}
                alt={item?.data?.mosaic_thumbnail?.alt}
              >
                {item.uid &&
                item?.data?.project_title?.text &&
                item?.data?.project_category ? (
                  <StyledLink
                    pageType={item?.data?.page_type}
                    pageHandle={item?.uid}
                  >
                    <StyledTitle
                      whiteTitle
                      type="h4"
                      className="Mosaic__MobileTitle"
                    >
                      {item?.data?.project_title?.text}
                    </StyledTitle>
                    <StyledCategory
                      type="secondary-small"
                      whiteText
                      uppercase
                      className="Mosaic__MobileCategory"
                    >
                      {item?.data?.project_category}
                    </StyledCategory>
                  </StyledLink>
                ) : (
                  <></>
                )}
              </MobileContainerShort>
            )
          }
        })}
    </MosaicWrapperMobileContainer>
  )
}

MosaicWrapperMobile.propTypes = {
  projects: PropTypes.array.isRequired,
}

export default MosaicWrapperMobile
