import styled from '@emotion/styled'

export const ProjectsLayoutContainer = styled.div``

export const SimpleWrapperContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 100px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 200px;
    align-items: center;
    padding-right: initial;
    padding-left: initial;
    margin-top: initial;
  }
`
