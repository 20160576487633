import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'
import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'

export const MosaicWrapperContainer = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    max-width: 2000px;
    margin: 0 auto;

    div:not(:nth-of-type(1)):not(:nth-of-type(2)) {
      margin-top: 80px;
    }

    .Mosaic__ImageTall {
      position: relative;
    }

    .Mosaic__ImageShort {
      position: relative;
    }

    div:nth-of-type(2n) {
      flex-direction: row-reverse;

      .Mosaic__ImageTall {
        margin-top: 40px;
        margin-right: 0px;
        margin-left: 40px;
      }
    }
  }
`

export const MainGalleryContainer = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    height: 1100px;
    display: flex;
    flex-wrap: wrap;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 40px;
    justify-content: center;

    :last-of-type {
      margin-top: 40px !important;
    }
  }
`

export const ImageTall = styled(BackgroundImage)`
  width: calc(50% - 40px);
  height: 100%;
  margin-bottom: 40px;
  margin-right: 40px;
  cursor: pointer;
  min-width: 450px;

  a {
    text-decoration: none;
  }

  .Mosaic__Title {
    /* opacity: 1;
    z-index: 4;
    position: relative;
    top: 75px;
    left: 69px;
    padding-top: 0px;
    padding-left: 0px; */
  }

  &:hover {
    .Mosaic__HoverContainerTall {
      background-color: ${props => props.theme.colors.terracota};
    }
    .Mosaic__StyledCategory {
      opacity: 1;
    }
  }
`

export const HoverContainerTall = styled.div`
  width: 350px;
  height: 350px;
  position: relative;
  top: 35px;
  left: 39px;
  background-color: transparent;
  z-index: 3;
  transition: background-color 0.7s;

  .Mosaic__StyledCategory {
    position: absolute;
    bottom: 40px;
    left: 30px;
  }
`

export const HoverContainerShort = styled.div`
  width: 350px;
  height: 350px;
  position: relative;
  top: 35px;
  left: 39px;
  background-color: transparent;
  z-index: 3;
  transition: background-color 0.7s;

  .Mosaic__StyledCategory {
    position: absolute;
    bottom: 40px;
    left: 30px;
  }
`

export const ImageShort = styled(BackgroundImage)`
  width: 100%;
  height: 50%;
  margin-bottom: 40px;
  cursor: pointer;
  min-width: 450px;

  a {
    text-decoration: none;
  }

  &:hover {
    .Mosaic__HoverContainerShort {
      background-color: ${props => props.theme.colors.terracota};
    }
    .Mosaic__StyledCategory {
      opacity: 1;
    }
  }
`

export const ImageShortContainer = styled.div`
  width: calc(50% - 40px);
  height: 100%;
`

export const StyledTitle = styled(Title)`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 40px 30px 0;
    font-weight: bold;
  }
`
export const StyledCategory = styled(Text)`
  @media (min-width: ${props => props.theme.breakpoints.m}) {
    opacity: 0;
    transition: opacity 700ms;
  }
`
