import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'
import LinkFormatter from '../../LinkFormatter'

export const MosaicWrapperMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 100px;
`

export const MobileContainerTall = styled(BackgroundImage)`
  width: 100%;
  height: 434px;
  margin-bottom: 45px;

  .Mosaic__MobileTitle {
    position: absolute;
    top: 45px;
    left: 35px;
    font-weight: bold;
  }

  .Mosaic__MobileCategory {
    position: absolute;
    bottom: 45px;
    left: 35px;
    font-weight: 300;
  }
`

export const MobileContainerShort = styled(BackgroundImage)`
  width: 100%;
  height: 305px;
  margin-bottom: 45px;

  .Mosaic__MobileTitle {
    position: absolute;
    top: 30px;
    left: 35px;
    font-weight: bold;
  }

  .Mosaic__MobileCategory {
    position: absolute;
    bottom: 30px;
    left: 35px;
    font-weight: 300;
  }
`
export const StyledLink = styled(LinkFormatter)`
  position: absolute;
  width: 100%;
  height: 100%;
`
